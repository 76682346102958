import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from './SEO';

const Home = () => {
  return (
    <>
      <SEO 
        title="Home"
        description="Senior Software Engineer with expertise in distributed systems, cloud architecture, and technical leadership. Building enterprise-scale solutions."
      />
      <section className="min-h-screen flex items-center relative bg-dark-300 overflow-hidden">
        {/* Animated background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute w-96 h-96 bg-accent-primary/10 rounded-full blur-3xl -top-48 -left-48 animate-float"></div>
          <div className="absolute w-96 h-96 bg-neon-purple/10 rounded-full blur-3xl -bottom-48 -right-48 animate-float" style={{ animationDelay: '2s' }}></div>
        </div>

        <div className="max-w-7xl mx-auto px-8 z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="space-y-8"
          >
            <motion.h1
              className="text-6xl md:text-7xl font-bold text-white mb-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Senior Software
              <span className="block text-transparent bg-clip-text bg-gradient-primary">
                Engineer
              </span>
            </motion.h1>

            <motion.h2
              className="text-3xl text-gray-400"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              Building Enterprise-Scale Solutions
            </motion.h2>

            <motion.p
              className="text-xl text-gray-400 max-w-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              Specialized in distributed systems, cloud architecture, and technical leadership. 
              Driving innovation through scalable solutions and engineering excellence.
            </motion.p>

            <motion.div
              className="flex flex-wrap gap-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              <Link to="/projects">
                <button className="px-8 py-4 bg-gradient-primary text-white rounded-lg font-semibold shadow-neon hover:shadow-neon-strong transition-all duration-300 hover:-translate-y-1">
                  View Projects
                </button>
              </Link>
              <a 
                href="/assert/documents/addjoy_barreto.pdf" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <button className="px-8 py-4 border-2 border-accent-primary text-accent-primary rounded-lg font-semibold hover:bg-accent-primary/10 transition-all duration-300">
                  Download Resume
                </button>
              </a>
            </motion.div>

            {/* Tech stack badges */}
            <motion.div
              className="flex flex-wrap gap-4 pt-8"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              {['Cloud Architecture', 'System Design', 'Team Leadership', 'Microservices'].map((tag, index) => (
                <motion.span
                  key={tag}
                  className="px-4 py-2 bg-dark-200 text-accent-primary rounded-full text-sm border border-accent-primary/20"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.7 + index * 0.1 }}
                >
                  {tag}
                </motion.span>
              ))}
            </motion.div>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default Home;